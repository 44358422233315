
import smoothscroll from "smoothscroll-polyfill";

window.onload=function(){
  var scroll = document.querySelectorAll('.fix');
  var startPos = 0, winScrollTop = 0;
  winScrollTop = this.scrollY;

  var Animation = function() {
    for(var i = 0; i < scroll.length; i++) {
      if( 50 < window.scrollY ) {
        scroll[i].classList.add('show');
      }  else{
        scroll[i].classList.remove('show');
      }
    }
  }
  window.addEventListener('scroll', Animation);
}

// kick off the polyfill!
smoothscroll.polyfill();

document.addEventListener("click", e => {
  const target = e.target;
  // clickした要素がclass属性、js-smooth-scrollを含まない場合は処理を中断
  if (!target.classList.contains("scroll")) return;
  e.preventDefault();
  const targetId = target.hash;
  document.querySelector(targetId).scrollIntoView({
    behavior: "smooth",
    block: "start"
  });
});
const pagetopBtn = document.querySelector('#pagetop');
pagetopBtn.addEventListener('click', () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
});